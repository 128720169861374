import { graphql } from 'gatsby'
import React from 'react'

import { IndexQueryQuery } from '../../../types/graphql-types'
import Meta from '../../components/meta/meta'
import Layout from '../../components/layout/layout'
import LightboxWrapper from '../../components/lightbox/lightboxwrapper'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const ClickAndReserve: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta

  return (
    <Layout edition="01-2021" location={location}>
      <Meta site={meta} />
      <div className="content-inner shadow-lg">
        <div
          className="page-header"
          style={{
            backgroundImage: 'url(../../img/lon-click-and-reserve-header.jpg)',
          }}
        ></div>
        <article className="post">
          <h1>Click & Reserve </h1>
          <div className="row">
            <div
              className="col-md-5 block-title py-2"
              style={{ borderTop: '5px solid #F1F1F4' }}
            >
              <h2>Support the retail through digital!</h2>
              <p>A new opportunity to drive customers to the stores.</p>
            </div>
            <div className="col-md-7">
              <p>
                <strong>
                  Supporting our retail network with new ways to bring customers
                  to their doors.
                </strong>
              </p>
              <p>
                Click and Reserve (C&R) feature allows customers to express
                their desire to experience our watches in person in our
                corporate and retailer’s boutiques.
              </p>
            </div>
          </div>
          <div className="spacer"></div>
          <h3>How it works ?</h3>
          <ol>
            <li>
              Customer finds a watch he would like to see in boutique on our
              website.
            </li>
            <li>
              Customer selects a boutique near him. Boutiques listed are
              corporate and/or retailers. Customer fills a small form with
              elementary data.
            </li>
            <li>
              An email is sent to customer, boutique and subsidiary. The
              boutique answers back to confirm the appointment or propose a new
              schedule.
            </li>
            <li>
              Customer comes in boutique with a clear interest for a watch.
            </li>
          </ol>
          <div className="spacer"></div>
          <div className="row">
            <div className="col-md-5 block-title py-2">
              <h2>
                A 60% to 80%
                <br />
                conversion rate
              </h2>
            </div>
            <div className="col-md-7">
              <p>
                We expect between 60% and 80% conversion rate with this feature.
                On the below link, you will find additional documentation and
                the procedure to apply for this new feature.
              </p>
              <p>The feature is live on our South Korean and Swiss Website.</p>
              <p>
                Markets already in the planning stages: United Kingdom, Japan,
                France
              </p>
            </div>
          </div>
          <div className="spacer"></div>
          <div className="row">
            <div className="col-md-7">
              <LightboxWrapper>
                <a href={'../../img/lon-click-and-reserve.jpg'}>
                  <img
                    src={'../../img/lon-click-and-reserve.jpg'}
                    className="img-fluid thumbnail"
                  />
                </a>
              </LightboxWrapper>
            </div>
            <div className="col-md-5 block-title py-2">
              <h2>Do you want “to catch the train” ?</h2>
              <a
                href="https://itsazrmgrswatchgroup-my.sharepoint.com/:f:/g/personal/lon-lehnil_swatchgroup_net/Ej-b_eIeC39JjT1BRKL_31MBcp2oobUa17J4N0-4U0eQEw?e=pxFjyS"
                className="btn btn-primary"
                rel="noopener"
                target="_blank"
              >
                Download documents
              </a>
            </div>
          </div>
          <div className="spacer"></div>
          <h3>Prerequisites</h3>
          <ul className="checkbox">
            <li>
              <ion-icon name="checkbox-outline" class="mr-3"></ion-icon> Having
              a website with your own domain
            </li>
            <li>
              <ion-icon name="checkbox-outline" class="mr-3"></ion-icon> Content
              translation
            </li>
            <li>
              <ion-icon name="checkbox-outline" class="mr-3"></ion-icon> Terms &
              Conditions review and adaptation.
            </li>
            <li>
              <ion-icon name="checkbox-outline" class="mr-3"></ion-icon> Data
              privacy review and adaptation
            </li>
            <li>
              <ion-icon name="checkbox-outline" class="mr-3"></ion-icon> Product
              logistics definition (product availability in the right place)
            </li>
            <li>
              <ion-icon name="checkbox-outline" class="mr-3"></ion-icon>{' '}
              Coordination of appointments definition
            </li>
            <li>
              <ion-icon name="checkbox-outline" class="mr-3"></ion-icon> Service
              level Agreement definition (customer management and approach)
            </li>
            <li>
              <ion-icon name="checkbox-outline" class="mr-3"></ion-icon>{' '}
              Communication plan (not mandatory)
            </li>
            <li>
              <ion-icon name="checkbox-outline" class="mr-3"></ion-icon>{' '}
              Retailers: 3rd parties’ agreements
            </li>
          </ul>
          <blockquote>
            <div className="d-flex">
              <img
                src={'../../img/nils-lehmann.jpg'}
                className="rounded-circle shadow-lg mr-3"
                style={{ width: '80px', height: '80px' }}
              />
              <div>
                <h4>Need further information?</h4>
                <p>
                  Please email{' '}
                  <a href="mailto:nils.lehmann@longines.com">
                    nils.lehmann@longines.com
                  </a>{' '}
                  and he will be glad to answer your questions and organize a
                  Skype call at your convenience.
                </p>
              </div>
            </div>
          </blockquote>
        </article>
      </div>
    </Layout>
  )
}

export default ClickAndReserve

export const pageQuery = graphql`
  query ClickAndReserveQuery_01_2021 {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
